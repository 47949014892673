import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTrashAlt, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import Spinner from './Spinner';

const NotificationCenter = () => {
    const [notifications, setNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const [filter, setFilter] = useState('all');  // 'all', 'unread', 'read'
    const [sort, setSort] = useState('date_desc'); // Sorting: newest, oldest, status
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(true);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        // Fetch notifications from the backend API
        fetchNotifications();
    }, [currentPage, filter, sort, searchQuery]);

    // Scroll to the top when the component mounts
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const fetchNotifications = () => {
        setLoading(true);
        const queryParams = new URLSearchParams({
            page: currentPage,
            filter: filter,
            sort: sort,
            search: searchQuery,
        });

        fetch(`${API_BASE_URL}/api/notifications?${queryParams.toString()}`)
            .then((response) => response.json())
            .then((data) => {
                setNotifications(data.notifications);
                setTotalPages(data.notifications.length > 0 ? data.total_pages : 1);
                const unreadCount = data.notifications.filter(notification => notification.status === 'unread').length;
                setUnreadCount(unreadCount);
            })
            .catch((error) => {
                console.error('Error fetching notifications:', error);
            });
            setLoading(false);
    };

    const navigate = useNavigate(); // For navigation

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const filteredNotifications = notifications.filter((notif) => {
        if (filter === 'all') return true;
        return notif.status === filter;
    });

    const markAsRead = (notificationId) => {
        fetch(`${API_BASE_URL}/notifications/mark_as_read/${notificationId}`, {
            method: 'POST',
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                setNotifications(notifications.map(notification =>
                    notification.id === notificationId ? { ...notification, status: 'read' } : notification
                ));
            }
        })
        .catch(error => console.error('Error marking notification as read:', error));
    };

    const deleteNotification = (notificationId) => {
        fetch(`${API_BASE_URL}/notifications/clear_notification/${notificationId}`, {
            method: 'POST',
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                setNotifications(notifications.filter(notification => notification.id !== notificationId));
            }
        })
        .catch(error => console.error('Error deleting notification:', error));
    };

    const markAllAsRead = () => {
        fetch(`${API_BASE_URL}/notifications/mark_all_as_read`, {
            method: 'POST',
            credentials: 'include',
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                // Update the state to set all notifications as read
                setNotifications(notifications.map(notification => ({ ...notification, status: 'read' })));
                setUnreadCount(0); // Reset unread count
            }
        })
        .catch(error => console.error('Error marking all notifications as read:', error));
    };    

    return (
        <div className="p-6 min-h-screen bg-gray-50 font-rubik">
            {/* Back to Home */}
            {/* <div className="mb-6 flex justify-between items-center">
                <button
                    className="flex items-center text-blue-600 hover:text-blue-800"
                    onClick={() => navigate('/landing-page')} // Navigate back to home
                >
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                    Back to Home
                </button>
            </div> */}

            {/* Header: Title and Mark All as Read */}
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-3xl font-bold">Notifications</h1>
                <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition">
                    Mark All as Read
                </button>
            </div>

            {/* Filters and Search */}
            <div className="flex justify-between mb-6">
                <div className="space-x-4">
                    <button
                        className={`p-2 rounded-full ${filter === 'all' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700 hover:bg-gray-400'}`}
                        onClick={() => {
                            setFilter('all');
                            setCurrentPage(1);
                        }}
                    >
                        All
                    </button>
                    <button
                        className={`p-2 rounded-full ${filter === 'unread' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700 hover:bg-gray-400'}`}
                        onClick={() => {
                            setFilter('unread');
                            setCurrentPage(1);
                        }}
                    >
                        Unread
                    </button>
                    <button
                        className={`p-2 rounded-full ${filter === 'read' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700 hover:bg-gray-400'}`}
                        onClick={() => {
                            setFilter('read');
                            setCurrentPage(1);
                        }}
                    >
                        Read
                    </button>
                </div>
                <input
                    type="search"
                    placeholder="Search..."
                    className="p-2 border rounded-lg w-1/3"
                    onChange={(e) => {
                        setSearchQuery(e.target.value);
                        setCurrentPage(1);
                    }}
                />
            </div>

            {/* Notification Cards */}
            {loading ? (
                <Spinner text="Fetching sent messages..." />
            ) : (
                <>
                    <div className="space-y-4">
                        {filteredNotifications.length > 0 ? (
                            filteredNotifications.map((notification) => (
                                <div
                                    key={notification.id}
                                    className="bg-white shadow-md p-4 rounded-lg flex justify-between items-center transition-transform hover:scale-y-105"
                                >
                                    {/* Notification Details */}
                                    <div className="flex items-center space-x-3">
                                        <span className={`inline-block w-3 h-3 rounded-full ${notification.status === 'unread' ? 'bg-red-500' : 'bg-gray-500'}`}></span>
                                        <div>
                                            <p className="font-normal">{notification.message}</p>
                                            <p className="text-xs text-gray-500">{notification.time}</p>
                                        </div>
                                    </div>

                                    {/* Action Icons */}
                                    <div className="space-x-4">
                                        <button className="text-blue-600 hover:text-blue-800" aria-label="Mark as Read" onClick={() => markAsRead(notification.id)}>
                                            <FontAwesomeIcon icon={faCheckCircle} size="sm" />
                                        </button>
                                        <button className="text-blue-600 hover:text-blue-800" aria-label="Delete" onClick={() => deleteNotification(notification.id)}>
                                            <FontAwesomeIcon icon={faTrashAlt} size="sm" />
                                        </button>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="p-3 text-gray-500 border border-gray-300 rounded-lg text-center">No notifications found.</div>
                        )}
                    </div>

                    {/* Pagination Controls */}
                    <div className="flex justify-between items-center mt-6">
                        <button
                            className={`px-4 py-2 rounded ${currentPage === 1 ? 'bg-gray-300' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        <span className="text-gray-700">Page {currentPage} of {totalPages}</span>
                        <button
                            className={`px-4 py-2 rounded ${currentPage === totalPages ? 'bg-gray-300' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages || notifications.length === 0}
                        >
                            Next
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default NotificationCenter;
