import React, { useState, useEffect } from "react";
// import CustomBreadcrumbs from './CustomBreadcrumbs';
import Spinner from './Spinner';

const ViewSentMessages = () => {
  const [messages, setMessages] = useState([]);
  const [expandedMessages, setExpandedMessages] = useState(new Set()); // Set to track expanded error details
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const messagesPerPage = 10;
  const [filter, setFilter] = useState('all');
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  // Scroll to the top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Fetch messages from API on component mount
  useEffect(() => {
    const fetchMessages = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(`${API_BASE_URL}/api/view_sent_messages`);
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || "Failed to fetch messages");
        }
        const data = await response.json();
        setMessages(data.messages); // Update with data from API
        setFilteredMessages(data.messages); // Initialize filteredMessages
      } catch (error) {
        console.error("Error fetching messages:", error);
        setError(error.message);
      } finally {
        setLoading(false); // Set loading to false after fetch
      }
    };

    fetchMessages();
  }, []);

  useEffect(() => {
    const today = new Date();

    const adjustToIST = (dateString) => {
      const istDateString = dateString + " +0530"; // Adds IST offset to date string
      return new Date(istDateString);
    };

    const isToday = (dateString) => {
      // const date = new Date(dateString);
      const date = adjustToIST(dateString);
      return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      );
    };

    const isThisWeek = (dateString) => {
      const date = adjustToIST(dateString);
      const dayOfWeek = today.getDay();
      const startOfWeek = new Date(today);
      startOfWeek.setDate(today.getDate() - dayOfWeek);
      startOfWeek.setHours(0, 0, 0, 0);
      return date >= startOfWeek && date <= today;
    };

    const isThisMonth = (dateString) => {
      const date = adjustToIST(dateString);
      return (
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      );
    };

    const filtered = messages
      .filter((message) => {
        if (filter === 'today') {
          return isToday(message.message_sent_time);
        }
        if (filter === 'this_week') return isThisWeek(message.message_sent_time);
        if (filter === 'this_month') return isThisMonth(message.message_sent_time);
        return true;
      })
      .filter((message) =>
        message.customer_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        message.message_template_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        message.customer_phone_number.toLowerCase().includes(searchQuery.toLowerCase())
      );

    setFilteredMessages(filtered);
    setCurrentPage(1);
  }, [searchQuery, messages, filter]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0); // Scroll to top on page change
  };

  const getStatusColor = (statusType) => {
    switch (statusType) {
      case "accepted":
        return "bg-blue-100 text-blue-600"; // light blue for "accepted"
      case "sent":
        return "bg-yellow-100 text-yellow-600"; // light yellow for "sent"
      case "delivered":
        return "bg-green-100 text-green-600"; // light green for "delivered"
      case "read":
        return "bg-indigo-100 text-indigo-600"; // light indigo for "read"
      case "failed":
        return "bg-red-100 text-red-600"; // light red for "failed"
      default:
        return "bg-gray-100 text-gray-600"; // gray for unknown statuses
    }
  };

  // Toggle error details visibility
  const toggleErrorDetails = (messageId) => {
    setExpandedMessages((prevExpanded) => {
      const newExpanded = new Set(prevExpanded);
      if (newExpanded.has(messageId)) {
        newExpanded.delete(messageId); // Collapse if already expanded
      } else {
        newExpanded.add(messageId); // Expand if not already expanded
      }
      return newExpanded;
    });
  };

  const paginatedMessages = filteredMessages.slice(
    (currentPage - 1) * messagesPerPage,
    currentPage * messagesPerPage
  );

  return (
    <div className="min-h-screen bg-gray-50 p-6 font-rubik">
      {/* <CustomBreadcrumbs /> */}
      <h2 className="text-3xl font-semibold mb-4">View Sent Messages</h2>
      <p className="text-gray-600 mb-6">Review the messages you've sent to customers.</p>

      {/* Display error message if exists */}
      {error && (
        <div className="mb-4 p-4 bg-red-100 text-red-800 border border-red-300 rounded-lg">
          <p>Error: {error}</p>
        </div>
      )}

      {loading ? (
        <Spinner text="Fetching sent messages..." />
      ) : (
        <div>
          {/* Search and Filter Section */}
          <div className="flex justify-between mb-6">
            <input
              type="search"
              placeholder="Search messages by recipient name, phone number, or template"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full md:w-1/3 p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-indigo-500"
            />

            <div className="space-x-2">
              <button
                  className={`p-2 rounded-full ${filter === 'all' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700 hover:bg-gray-400'}`}
                  onClick={() => {
                      setFilter('all');
                      setCurrentPage(1);
                  }}
              >
                  All
              </button>
              <button
                  className={`p-2 rounded-full ${filter === 'today' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700 hover:bg-gray-400'}`}
                  onClick={() => {
                      setFilter('today');
                      setCurrentPage(1);
                  }}
              >
                  Today
              </button>
              <button
                  className={`p-2 rounded-full ${filter === 'this_week' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700 hover:bg-gray-400'}`}
                  onClick={() => {
                      setFilter('this_week');
                      setCurrentPage(1);
                  }}
              >
                  This Week
              </button>
              <button
                  className={`p-2 rounded-full ${filter === 'this_month' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700 hover:bg-gray-400'}`}
                  onClick={() => {
                      setFilter('this_month');
                      setCurrentPage(1);
                  }}
              >
                  This Month
              </button>
            </div>
          </div>

          {/* Messages List */}
          <div className="space-y-4">
            {paginatedMessages.map((message) => (
              <div key={message.id} className="p-4 bg-white rounded-lg shadow-md flex flex-col">
                <div className="flex justify-between items-center">
                  <div>
                    <h4 className="text-md font-semibold text-gray-800">
                      To: {message.customer_name} ({message.customer_phone_number})
                    </h4>
                    <p className="text-sm text-gray-500">Date: {message.message_sent_time}</p>
                    <p className="text-sm text-gray-700 mt-2">{message.message_template_name}</p>
                  </div>
                  <div>
                    <span className={`text-sm font-semibold py-1 px-3 rounded-lg ${getStatusColor(message.latest_status)}`}>
                      {/* {message.latest_status} */}
                      {message.latest_status.charAt(0).toUpperCase() + message.latest_status.slice(1)}
                    </span>
                  </div>
                </div>

                {/* Status Journey */}
                <div className="mt-4 space-y-2">
                  <div className="flex justify-between items-center">
                    {message.all_status_timestamps.map((status) => (
                      <div key={status.type} className="flex items-center space-x-2">
                        <span className="text-sm font-medium text-gray-700">{status.type.toUpperCase()}</span>
                        <span className="text-sm text-gray-500">{new Date(status.timestamp).toLocaleString()}</span>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Toggleable Error Details */}
                {message.latest_status === "failed" && message.error_details && (
                  <div className="mt-4">
                    <button
                      onClick={() => toggleErrorDetails(message.id)}
                      className="text-sm text-blue-500 font-semibold focus:outline-none"
                    >
                      {expandedMessages.has(message.id) ? "Hide Details" : "View Details"}
                    </button>

                    {expandedMessages.has(message.id) && (
                      <div className="mt-2 p-2 bg-red-50 border border-red-200 rounded-md">
                        <h5 className="text-red-700 font-semibold">Error Details:</h5>
                        {message.error_details.error_code && (
                          <p className="text-sm text-red-600">Error Code: {message.error_details.error_code}</p>
                        )}
                        {message.error_details.error_title && (
                          <p className="text-sm text-red-600">Error Title: {message.error_details.error_title}</p>
                        )}
                        {message.error_details.error_message && (
                          <p className="text-sm text-red-600">Error Message: {message.error_details.error_message}</p>
                        )}
                        {message.error_details.error_data && (
                          <p className="text-xs text-red-600 break-words">Error Data: {message.error_details.error_data}</p>
                        )}
                        {/* {message.error_details.error_data && (
                          <pre className="text-xs text-red-500 mt-2 break-words whitespace-pre-wrap overflow-auto max-w-full">
                            Error Data: {JSON.stringify(message.error_details.error_data, null, 2)}
                          </pre>
                        )} */}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* Pagination Controls */}
          <div className="flex justify-between items-center mt-8">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              // className="px-4 py-2 text-sm bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400"
              className={`px-4 py-2 rounded-lg ${currentPage === 1 ? 'bg-gray-300' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
            >
              Previous
            </button>
            <p className="text-gray-600">
              Page {currentPage} of {Math.ceil(filteredMessages.length / messagesPerPage)}
            </p>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === Math.ceil(filteredMessages.length / messagesPerPage) || filteredMessages.length === 0}
              // className="px-4 py-2 text-sm bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400"
              className={`px-4 py-2 rounded-lg ${currentPage === Math.ceil(filteredMessages.length / messagesPerPage) || filteredMessages.length === 0 ? 'bg-gray-300' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewSentMessages;
