import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Dashboard from './components/Dashboard';
import MessageTemplates from './components/MessageTemplates';
import Sidebar from './components/Sidebar';
import LandingPage from './components/LandingPage';
import Notifications from './components/Notifications';
import Sample from './components/Sample';
import NotificationCenter from './components/NotificationCenter';
import UserProfile from './components/Profile';
import MessageTemplatesNew from './components/MessageTemplatesNew';
import MessageTemplateForm from './components/MessageTemplateForm';
import MessagingOverview from './components/MessagingOverview'
import './index.css'; // Assuming you are using Tailwind CSS or a similar utility-first CSS framework
import TemplateApproval from './components/TemplateApproval';
import ViewSentMessages from './components/ViewSentMessages';
import ViewSentFlows from './components/ViewSentFlows';
import Reports from './components/Reports';
import Analytics from './components/Analytics';
import ViewSentMessagesV2 from './components/ViewSentMessagesV2';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import SendMessage from './components/SendAMessage';
import SendFlow from './components/SendAFlow';
import Flows from './components/Flows';
import DashboardV1 from './components/DashboardV1';
import MessageTemplateLevelReportCharts from './components/ReportsCharts';
import ReportsOverview from './components/ReportsOverview';
import MessageTemplateLevelReport from './components/MessageTemplateLevelReport';
import FlowLevelReport from './components/FlowLevelReport';
import Customers from './components/Customers';
import AddCustomerSegment from './components/AddCustomerSegment';
import CustomerSegmentsPage from './components/CustomerSegmentsPage';
import CustomerSegmentsPageV2 from './components/CustomerSegmentsPageV2';
import CustomerSegmentsPageV3 from './components/CustomerSegmentsPageV3';
import CustomerSegmentsPageV4 from './components/CustomerSegmentsPageV4';
import CustomerSegmentsPageV5 from './components/CustomerSegmentsPageV5';
import CustomerSegmentsPageV6 from './components/CustomerSegmentsPageV6';
import SettingsPage from './components/SettingsPage';
import HomePage from './components/Tab';
import TopBar from './components/TopBar';
import IconsSidebar from './components/IconsSidebar';
import History from './components/History';
import CustomerSegmentsPageV7 from './components/CustomerSegmentsPageV7';
import LandingPageList from './components/LandingPageList';
import LandingPageListV1 from './components/LandingPageListV1';
import LandingPageListV2 from './components/LandingPageListV2';
import ScheduleMessage from './components/ScheduleMessage';
import ScheduledMessagesList from './components/ScheduledMessagesList';
import ScheduledMessagesCard from './components/ScheduledMessagesCard';
import ScheduledMessagesCardSlider from './components/ScheduledMessagesCardSlider';
import LandingV1 from './components/LandingV1';
import useInactivityTimeout from './components/useInactivityTimeout';
import MessagesReport from './components/MessagesReport';
import FlowsReport from './components/FlowsReport';
import SendMessageToSegment from './components/SendMessageToSegment';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
  // const userName = "Raj";
  const [userName, setUsername] = useState('');
  const [userDetails, setUserdetails] = useState('');

  // useInactivityTimeout(1 * 60 * 1000);

  // Function to handle successful login and set authentication state
  const handleLoginSuccess = (token, userDetails) => {
    localStorage.setItem('token', token);
    setUserdetails(userDetails);
    setUsername(userDetails.user_name);
    setIsAuthenticated(true); // Update state to re-render with new authentication status
  };

  useEffect(() => {
    setIsAuthenticated(!!localStorage.getItem('token'));
  }, []);
  
  return (
    <Router>
      <Routes>
        {/* <Route path="/login" element={<Login />} /> */}
        <Route path="/" element={<Login onLoginSuccess={handleLoginSuccess} />} />
        {/* Routes that have the Sidebar layout */}
        <Route path="/landing-page" element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <div className="flex">
              <IconsSidebar />
              <div className="ml-20 flex-1 bg-gray-100 p-8">
                <LandingPage userName={userName} />
              </div>
            </div>
          </ProtectedRoute>
        }/>

        <Route path="/landing" element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <div className="flex">
              <IconsSidebar />
              <div className="ml-20 flex-1 bg-gray-50 p-8">
                <LandingV1 userName={userName} />
              </div>
            </div>
          </ProtectedRoute>
        }/>

        <Route path="/landing-page-list" element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <div className="flex">
              <IconsSidebar />
              <div className="ml-20 flex-1 bg-gray-100 p-8">
                <LandingPageList userName={userName} />
              </div>
            </div>
          </ProtectedRoute>
        }/>

        <Route path="/landing-page-list-v1" element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <div className="flex">
              <IconsSidebar />
              <div className="ml-20 flex-1 bg-gray-100 p-8">
                <LandingPageListV1 userName={userName} />
              </div>
            </div>
          </ProtectedRoute>
        }/>

        <Route path="/landing-page-list-v2" element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <div className="flex">
              <IconsSidebar />
              <div className="ml-20 flex-1 bg-gray-100 p-8">
                <LandingPageListV2 userName={userName} />
              </div>
            </div>
          </ProtectedRoute>
        }/>
        
        <Route path="/dashboard" element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <div className="flex">
              <IconsSidebar />
              <div className="ml-20 flex-1 bg-gray-50 p-8">
                <Dashboard />
              </div>
            </div>
          </ProtectedRoute>
        }/>
        
        <Route path="/message-templates" element={
          <div className="flex">
            <IconsSidebar />
            <div className="ml-20 flex-1 bg-gray-50 p-8">
              <MessageTemplatesNew />
            </div>
          </div>
        }/>
        
        <Route path="/notifications" element={
          <div className="flex">
            <IconsSidebar />
            <div className="ml-20 flex-1 p-8">
              <Notifications />
            </div>
          </div>
        }/>

        <Route path="/sample" element={
          <div className="flex">
            <IconsSidebar />
            <div className="ml-20 flex-1 p-8">
              <Sample />
            </div>
          </div>
        }/>

        {/* Route with Full Screen Layout for Notification Center */}
        <Route path="/notification-center" element={
          <div className="flex">
            <IconsSidebar />
            <div className="ml-20 flex-1 bg-gray-50 p-8">
              <NotificationCenter />
            </div>
          </div>
        }/>

        <Route path="/profile" element={
          <div className="flex">
            <IconsSidebar />
            <div className="ml-20 flex-1 p-8">
              <UserProfile userDetails={userDetails} />
            </div>
          </div>
        }/>

        <Route path="/templates" element={
          <div className="flex">
            <IconsSidebar />
            <div className="ml-64 flex-1 bg-gray-100 p-8">
              <MessageTemplates />
            </div>
          </div>
        }/>

        <Route path="/messaging" element={
          <div className="flex">
            <IconsSidebar />
            <div className="ml-64 flex-1 p-8">
              <MessagingOverview />
            </div>
          </div>
        }/>

        <Route path="/message-templates/add-template" element={
          <div className="flex">
            <IconsSidebar />
            <div className="ml-20 flex-1 bg-gray-100 p-8">
              <MessageTemplateForm />
            </div>
          </div>
        }/>

        <Route path="/message-templates/approval" element={
          <div className="flex">
            <IconsSidebar />
            <div className="ml-20 flex-1 p-8">
              <TemplateApproval />
            </div>
          </div>
        }/>

        <Route path="/message-templates/send-a-message" element={
          <div className="flex">
            <IconsSidebar />
            <div className="ml-20 flex-1 bg-gray-100 p-8">
              <SendMessage />
            </div>
          </div>
        }/>

        <Route path="/history" element={
          <div className="flex min-h-screen">
            <IconsSidebar />
            <div className="ml-20 flex-1 bg-gray-50 px-8 py-8">
              <History />
            </div>
          </div>
        }/>

        <Route path="/history/view-sent-messages" element={
          <div className="flex">
            <IconsSidebar />
            {/* <div className="ml-64 flex-1 bg-gray-100 p-8"> */}
            <div className="ml-20 flex-1 bg-gray-50 px-8 py-8">
              <ViewSentMessages />
            </div>
          </div>
        }/>

        <Route path="/view-sent-messages-2" element={
          <div className="flex">
            <IconsSidebar />
            <div className="ml-64 flex-1 bg-gray-100 p-8">
              <ViewSentMessagesV2 />
            </div>
          </div>
        }/>

        <Route path="/flows" element={
          <div className="flex">
            <IconsSidebar />
            <div className="ml-20 flex-1 bg-gray-50 p-8">
              <Flows />
            </div>
          </div>
        }/>

        <Route path="/flows/send-a-flow" element={
          <div className="flex">
            <IconsSidebar />
            <div className="ml-20 flex-1 bg-gray-50 p-8">
              <SendFlow />
            </div>
          </div>
        }/>

        <Route path="/history/view-sent-flows" element={
          <div className="flex">
            <IconsSidebar />
            {/* <div className="ml-64 flex-1 bg-gray-100 p-8"> */}
            <div className="ml-20 flex-1 bg-gray-50 px-8 py-8">
              <ViewSentFlows />
            </div>
          </div>
        }/>

        <Route path="/customers" element={
          <div className="flex">
            <IconsSidebar />
            <div className="ml-64 flex-1 bg-gray-100 p-8">
              <Customers />
            </div>
          </div>
        }/>

        <Route path="/reports" element={
          // <div className="flex">
          //   <IconsSidebar />
          //   <div className="ml-64 flex-1 bg-gray-100 p-8">
          <div className="flex min-h-screen">
            <IconsSidebar />
            <div className="ml-20 flex-1 bg-gray-50 px-8 py-8">
              <ReportsOverview />
            </div>
          </div>
        }/>

        <Route path="/customer-segments" element={
          <div className="flex">
            <IconsSidebar />
              <div className="ml-64 flex-1 bg-gray-100 p-8">
                <CustomerSegmentsPage />
              </div>
          </div>
        }/>

        <Route path="/customer-segments-v2" element={
          <div className="flex">
            <IconsSidebar />
              <div className="ml-64 flex-1 bg-gray-100 p-8">
                <CustomerSegmentsPageV2 />
              </div>
          </div>
        }/>

        <Route path="/customer-segments-v3" element={
          <div className="flex">
            <IconsSidebar />
              <div className="ml-64 flex-1 bg-gray-100 p-8">
                <CustomerSegmentsPageV3 />
              </div>
          </div>
        }/>

        <Route path="/customer-segments-v4" element={
          <div className="flex">
            <IconsSidebar />
              <div className="ml-64 flex-1 bg-gray-100 p-8">
                <CustomerSegmentsPageV4 />
              </div>
          </div>
        }/>

        <Route path="/customer-segments-v5" element={
          <div className="flex-1 min-h-screen">
            <CustomerSegmentsPageV5 />
          </div>
        }/>

        <Route path="/customer-segments-v6f" element={
          <div className="flex-1 min-h-screen">
            <CustomerSegmentsPageV6 />
          </div>
        }/>

        <Route path="/customer-segments-v6" element={
          <div className="flex min-h-screen">
            <IconsSidebar />
            <div className="ml-20 flex-1 bg-gray-100 px-8 py-8">
              <CustomerSegmentsPageV6 />
            </div>
          </div>
        }/>

        <Route path="/customer-segments-v7" element={
          <div className="flex min-h-screen">
            <IconsSidebar />
            <div className="ml-20 flex-1 bg-gray-100 px-8 py-8">
              <CustomerSegmentsPageV7 />
            </div>
          </div>
        }/>

        <Route path="/customer-segments-v7/add-segment" element={
          <div className="flex min-h-screen">
            <IconsSidebar />
            <div className="ml-20 flex-1 bg-gray-100 p-8">
              <AddCustomerSegment />
            </div>
          </div>
        }/>

        <Route path="/reports/message-template" element={
          <div className="flex">
            <IconsSidebar />
            <div className="ml-20 flex-1 bg-gray-50 px-8 py-8">
              <MessageTemplateLevelReport />
            </div>
          </div>
        }/>

        <Route path="/reports-charts" element={
          <div className="flex">
            <IconsSidebar />
            <div className="ml-20 flex-1 bg-gray-50 px-8 py-8">
              <MessageTemplateLevelReportCharts />
            </div>
          </div>
        }/>

        <Route path="/reports/flow-level" element={
          <div className="flex-1 min-h-screen">
            <IconsSidebar />
            <div className="ml-20 flex-1 bg-gray-50 px-8 py-8">
              <FlowLevelReport />
            </div>
          </div>
        }/>

        <Route path="/analytics" element={
          <div className="flex-1 min-h-screen">
            <IconsSidebar />
            <div className="ml-20 flex-1 bg-gray-50 px-8 py-8">
              <Analytics />
            </div>
          </div>
        }/>

        <Route path="/dashboardv1" element={
          <div className="flex-1 min-h-screen">
            <DashboardV1 />
          </div>
        }/>

        <Route path="/settings" element={
          <div className="flex-1 min-h-screen">
            <SettingsPage />
          </div>
        }/>

        <Route path="/settings-new" element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <div className="flex">
              <IconsSidebar />
              <div className="ml-20 flex-1 bg-gray-100 px-8 py-8">
                <SettingsPage userDetails={userDetails} />
              </div>
            </div>
          </ProtectedRoute>
        }/>

        <Route path="/home" element={
          <div className="flex-1 min-h-screen">
            <HomePage />
          </div>
        }/>

        <Route path="/top" element={
          <div className="flex-1 min-h-screen">
            <TopBar />
          </div>
        }/>

        <Route path="/scheduled-messages-cards/schedule-message" element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <div className="flex">
              <IconsSidebar />
              <div className="ml-20 flex-1 bg-gray-50 px-8 py-8">
                <ScheduleMessage />
              </div>
            </div>
          </ProtectedRoute>
        }/>

        <Route path="/scheduled-messages" element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <div className="flex">
              <IconsSidebar />
              <div className="ml-20 flex-1 bg-gray-100 px-8 py-8">
                <ScheduledMessagesList />
              </div>
            </div>
          </ProtectedRoute>
        }/>

        <Route path="/scheduled-messages-card" element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <div className="flex">
              <IconsSidebar />
              <div className="ml-20 flex-1 bg-gray-100 px-8 py-8">
                <ScheduledMessagesCard />
              </div>
            </div>
          </ProtectedRoute>
        }/>

        <Route path="/scheduled-messages-cards" element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <div className="flex">
              <IconsSidebar />
              <div className="ml-20 flex-1 bg-gray-50 px-8 py-8">
                <ScheduledMessagesCardSlider />
              </div>
            </div>
          </ProtectedRoute>
        }/>

        <Route path="/reports/messages" element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <div className="flex">
              <IconsSidebar />
              <div className="ml-20 flex-1 bg-gray-50 px-8 py-8">
                <MessagesReport />
              </div>
            </div>
          </ProtectedRoute>
        }/>

        <Route path="/reports/flows" element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <div className="flex">
              <IconsSidebar />
              <div className="ml-20 flex-1 bg-gray-50 px-8 py-8">
                <FlowsReport />
              </div>
            </div>
          </ProtectedRoute>
        }/>

        <Route path="/send-to-segment" element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <div className="flex">
              <IconsSidebar />
              <div className="ml-20 flex-1 bg-gray-50 px-8 py-8">
                <SendMessageToSegment />
              </div>
            </div>
          </ProtectedRoute>
        }/>
      </Routes>
    </Router>
  );
}

export default App;
