// CustomBreadcrumbs.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const routeNames = {
    '/messaging': 'Messaging',
    '/message-templates': 'Message Templates',
    '/message-templates/approval': 'Template Approval',
    '/message-templates/add-template': 'Add Template',
    '/message-templates/send-a-message': 'Send a Message',
    '/view-sent-messages': 'View Sent Messages',
    '/view-sent-flows': 'View Sent Flows',
    '/customers': 'Customers',
    '/customer-segments-v7': 'Customer Segments',
    '/customer-segments-v7/add-segment': 'Add Segment',
    '/reports': 'Reports',
    '/reports-charts': 'Reports',
    '/reports/message-template': 'Message Template Level Report',
    '/reports/messages': 'Message Template Level Report',
    '/reports/flow-level': 'Flow Level Report',
    '/reports/flows': 'Flow Level Report',
    '/analytics': 'Analytics',
    '/flows': 'Flows',
    '/flows/send-a-flow': 'Send a Flow',
    '/scheduled-messages-card': 'Scheduled Messages',
    '/scheduled-messages-cards': 'Scheduled Messages',
    '/scheduled-messages-cards/schedule-message': 'Schedule a Message',
    '/send-to-segment': 'Send Message to Segment'
    // Add more route mappings as needed
};

const CustomBreadcrumbs = () => {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);

    return (
        <nav aria-label="breadcrumb" className="text-sm mb-4 text-gray-600">
            <ol className="flex">
                <li>
                    <Link
                        to="/landing"
                        className="mr-2 px-2 py-1 rounded text-blue-600 hover:bg-blue-100 hover:text-blue-800 transition-colors"
                    >
                        Home
                    </Link>
                    {pathnames.length > 0 && <span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>}
                </li>
                {pathnames.map((value, index) => {
                    const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                    const isLast = index === pathnames.length - 1;

                    return (
                        <li key={to}>
                            {!isLast ? (
                                <>
                                    <Link
                                        to={to}
                                        className="mr-2 px-2 py-1 rounded text-blue-600 hover:bg-blue-100 hover:text-blue-800 transition-colors hover:scale-105 transform"
                                    >
                                        {routeNames[to] || value}
                                    </Link>
                                    <span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>
                                </>
                            ) : (
                                <span className="text-gray-500 px-2 py-1">{routeNames[to] || value}</span>
                            )}
                        </li>
                    );
                })}
            </ol>
        </nav>
    );
};

export default CustomBreadcrumbs;
