import React, { useState, useEffect } from 'react';
import CustomBreadcrumbs from './CustomBreadcrumbs';
import Spinner from './Spinner';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

const MessagesReport = () => {
  const [messageStats, setMessageStats] = useState([]);
  const [dateRange, setDateRange] = useState('this_month');
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [templates, setTemplates] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateError, setDateError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasQueried, setHasQueried] = useState(false);
  const [viewType, setViewType] = useState('cards');
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/wa_templates?status=APPROVED`);
        if (!response.ok) throw new Error('Failed to fetch templates');
        const data = await response.json();
        setTemplates(data.templates);
      } catch (error) {
        console.error('Error fetching templates:', error);
      }
    };
    fetchTemplates();
  }, []);

  useEffect(() => {
    if (startDate && endDate && new Date(startDate) > new Date(endDate)) {
      setDateError('Start date cannot be after the end date.');
    } else {
      setDateError('');
    }
  }, [startDate, endDate]);

  const fetchReportData = async () => {
    if (dateError) return;
    setIsLoading(true);
    setHasQueried(true);
    try {
      let url = `${API_BASE_URL}/api/message_reports?template=${selectedTemplate}`;
      if (dateRange === 'custom_range' && startDate && endDate) {
        url += `&dateRange=custom_range&startDate=${startDate}&endDate=${endDate}`;
      } else {
        url += `&dateRange=${dateRange}`;
      }
      const response = await fetch(url);
      if (!response.ok) throw new Error('Failed to fetch report data');
      const data = await response.json();
      setMessageStats(data.message_stats);
    } catch (error) {
      console.error('Error fetching report data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const isRunQueryDisabled = dateRange === 'custom_range' && (!startDate || !endDate || dateError);
  const chartHeight = messageStats.length > 0 ? 450 : 300;

  return (
    <div className="min-h-screen bg-gray-50 font-rubik flex">
      {/* Side Panel */}
      <div className="w-1/5 p-4 bg-white rounded-lg shadow-md mr-6">
        <h3 className="text-xl font-semibold mb-4">Filters</h3>
        <label className="block mb-2 font-medium">Date Range</label>
        <select
          className="p-2 border rounded-md w-full mb-4"
          value={dateRange}
          onChange={(e) => setDateRange(e.target.value)}
        >
          <option value="this_month">This Month</option>
          <option value="last_month">Last Month</option>
          <option value="custom_range">Custom Range</option>
        </select>

        {dateRange === "custom_range" && (
          <div>
            <label className="block mb-2 font-medium">Start Date:</label>
            <input className="p-2 border rounded-lg focus:outline-none w-full mb-4" type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            {dateError && <p className="text-red-600 mb-2">{dateError}</p>}
            <label className="block mb-2 font-medium">End Date:</label>
            <input className="p-2 border rounded-lg focus:outline-none w-full mb-4" type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
          </div>
        )}

        <label className="block mb-2 font-medium">Template</label>
        <select
          className="p-2 border rounded-md w-full"
          value={selectedTemplate}
          onChange={(e) => setSelectedTemplate(e.target.value)}
        >
          <option value="">All Templates</option>
          {templates.map((template) => (
            <option key={template.id} value={template.name}>
              {template.name}
            </option>
          ))}
        </select>

        <button
          className={`mt-4 w-full px-4 py-2 font-semibold rounded-md shadow-sm ${isRunQueryDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 text-white hover:bg-blue-700'}`}
          onClick={fetchReportData}
          disabled={isRunQueryDisabled}
        >
          Run Query
        </button>

        <div className="mt-6">
        <label className="block mb-2 font-medium">View Type</label>
          <select
            className="p-2 border rounded-md w-full"
            value={viewType}
            onChange={(e) => setViewType(e.target.value)}
          >
            <option value="chart">Bar Chart</option>
            <option value="cards">Success Rate Cards</option>
          </select>
        </div>
      </div>

      <div className="flex-1">
        <CustomBreadcrumbs />
        <h2 className="text-3xl font-semibold mb-4">Message Template Level Report</h2>
        <p className="text-gray-600 mb-6">Analyze message activity based on selected filters.</p>

        {isLoading ? (
          <Spinner text="Fetching the report..." />
        ) : (
          <div>
            {hasQueried ? (
              messageStats.length > 0 ? (
                viewType === 'chart' ? (
                  <div className="bg-white shadow-md rounded-lg p-6 mb-8" style={{ height: `${chartHeight}px` }}>
                    <Bar
                      data={{
                        labels: messageStats.map((stat) => stat.templateName),
                        datasets: [
                          {
                            label: 'Messages Sent',
                            data: messageStats.map((stat) => stat.sentCount),
                            backgroundColor: '#60A5FA',
                          },
                          {
                            label: 'Messages Failed',
                            data: messageStats.map((stat) => stat.failedCount),
                            backgroundColor: '#F87171',
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        scales: {
                          x: { grid: { display: false }, ticks: { font: { size: 12 }, color: '#374151' } },
                          y: { beginAtZero: true, grid: { color: '#E5E7EB', borderDash: [5, 5] }, ticks: { font: { size: 12 }, color: '#374151' } },
                        },
                        plugins: {
                          legend: { labels: { font: { size: 12 }, color: '#374151' } },
                          tooltip: { backgroundColor: '#111827', titleColor: '#F9FAFB', bodyColor: '#F9FAFB' },
                        },
                      }}
                    />
                  </div>
                ) : (
                  <div className="grid gap-6">
                    {messageStats.map((stat) => (
                      <div key={stat.templateId} className="p-4 bg-white rounded-lg shadow-md">
                        <div className="flex justify-between items-center mb-2">
                          <h4 className="text-lg font-semibold text-gray-800">{stat.templateName}</h4>
                          <span
                            className={`px-2 py-1 text-xs font-semibold rounded-md ${
                              stat.successRate >= 100 ? 'bg-green-100 text-green-600' : stat.successRate >= 80 ? 'bg-yellow-100 text-yellow-600' : 'bg-red-100 text-red-600'
                            }`}
                          >
                            {stat.successRate.toFixed(2)}%
                          </span>
                        </div>
                        <div className="relative h-3 rounded-full bg-gray-200">
                          <div
                            className="absolute top-0 left-0 h-3 rounded-full"
                            style={{
                              width: `${Math.min(stat.successRate, 100)}%`,
                              backgroundColor: stat.successRate >= 100 ? '#34D399' : stat.successRate >= 80 ? '#FBBF24' : '#F87171',
                            }}
                          />
                        </div>
                        <div className="flex justify-between text-sm mt-2 text-gray-700">
                        <span>{`${stat.deliveredCount.toLocaleString()} sent / ${stat.goalCount.toLocaleString()} total`}</span>
                        <span>{stat.successRate >= 100 ? 'Sent to all successfully!' : ''}</span>
                      </div>
                      </div>
                    ))}
                  </div>
                )
              ) : (
                <p>No data found for the selected filters.</p>
              )
            ) : (
              <p>Click "Run Query" to view the report.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MessagesReport;
