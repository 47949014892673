import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';

const Login = ({ onLoginSuccess }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/login`, {
                username,
                password
            });
            // const token = response.data.access_token;
            const { access_token, user_details } = response.data;
            // localStorage.setItem('token', token);  // Store token in localStorage
            onLoginSuccess(access_token, user_details);
            navigate('/landing');  // Redirect to a protected route after login
        } catch (err) {
            setError('Invalid username or password');
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-white font-rubik">
            <div className="bg-white p-8 rounded shadow-2xl w-full max-w-xl">
                <h2 className="text-2xl font-bold mb-4 text-center">Login</h2>
                <form onSubmit={handleLogin}>
                    <div className="mb-4">
                        <label className="block font-normal text-gray-700"><FontAwesomeIcon icon={faUser} className="mr-2" />Username</label>
                        <input
                            type="text"
                            className="w-full p-2 border border-gray-300 rounded mt-1"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="Enter your username"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block font-normal text-gray-700"><FontAwesomeIcon icon={faLock} className="mr-2" />Password</label>
                        <input
                            type="password"
                            className="w-full p-2 border border-gray-300 rounded mt-1"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter your password"
                            required
                        />
                    </div>
                    {error && <p className="text-red-500 text-sm">{error}</p>}
                    <button className="w-full bg-blue-500 text-white p-2 rounded mt-4">Login</button>
                </form>
            </div>
        </div>
    );
};

export default Login;
