import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import CustomBreadcrumbs from './CustomBreadcrumbs';
import Spinner from './Spinner';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Analytics = () => {
    const [reportData, setReportData] = useState({
        sent_messages: {},
        failed_messages: {},
        sent_flows: {},
        failed_flows: {}
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedYear, setSelectedYear] = useState("this-year"); // "this-year" or "last-year"
    const token = localStorage.getItem('token');
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    // Scroll to the top when the component mounts
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const fetchData = async (year) => {
        setLoading(true);
        setError(null);
        try {
            // const response = await axios.get(`/api/reports?year=${year}`);
            const response = await axios.get(`${API_BASE_URL}/api/reports?year=${year}`);
            setReportData(response.data);
        } catch (err) {
            setError('Failed to load report data');
        } finally {
            setLoading(false);
        }
    };

    const calculateSuccessRate = (sent, failed) => {
        return sent > 0 ? (((sent - failed) / sent) * 100).toFixed(2) : '0.00';
    };

    const totalSentMessages = Object.values(reportData.sent_messages).reduce((a, b) => a + b, 0);
    const totalFailedMessages = Object.values(reportData.failed_messages).reduce((a, b) => a + b, 0);
    const successRate = totalSentMessages > 0 ? (((totalSentMessages - totalFailedMessages) / totalSentMessages) * 100).toFixed(2) : "0.00";
    const totalSentFlows = Object.values(reportData.sent_flows).reduce((a, b) => a + b, 0);
    const totalFailedFlows = Object.values(reportData.failed_flows).reduce((a, b) => a + b, 0);
    const flowSuccessRate = totalSentFlows > 0 ? (((totalSentFlows - totalFailedFlows) / totalSentFlows) * 100).toFixed(2) : "0.00";

    useEffect(() => {
        // Fetch data based on the selected year
        const year = selectedYear === "this-year" ? new Date().getFullYear() : new Date().getFullYear() - 1;
        fetchData(year);
    }, [selectedYear]);

    // if (loading) return <p className="text-center text-lg text-gray-500">Loading...</p>;
    // if (error) return <p className="text-center text-lg text-red-500">{error}</p>;

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const getMonthlyData = (data) => months.map((_, i) => data[i + 1] || 0);

    const chartData = {
        labels: months,
        datasets: [
            {
                label: 'Sent Messages',
                data: getMonthlyData(reportData.sent_messages),
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: true,
            },
            {
                label: 'Failed Messages',
                data: getMonthlyData(reportData.failed_messages),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                fill: true,
            },
            {
                label: 'Sent Flows',
                data: getMonthlyData(reportData.sent_flows),
                borderColor: 'rgb(54, 162, 235)',
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                fill: true,
            },
            {
                label: 'Failed Flows',
                data: getMonthlyData(reportData.failed_flows),
                borderColor: 'rgb(255, 206, 86)',
                backgroundColor: 'rgba(255, 206, 86, 0.2)',
                fill: true,
            }
        ]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false, // Allows setting a custom height
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: `Monthly Message and Flow Report (${selectedYear === "this-year" ? "This Year" : "Last Year"})`,
            },
        },
    };

    return (
        <div className="font-rubik p-6 bg-gray-50 min-h-screen">
            {/* Breadcrumbs */}
            {/* <CustomBreadcrumbs /> */}

            {/* Title and Year Selector */}
            <div className="flex items-center justify-between mb-6">
                <div>
                    {/* <h2 className="text-2xl font-bold text-gray-800 mb-6">Analytics</h2> */}
                    <h2 className="text-3xl font-semibold text-gray-800 mb-4">Analytics</h2>
                    <p className="text-gray-600 mb-6">View monthly statistics for messages and flows.</p>
                </div>

                {/* Year Selection Dropdown */}
                <div className="flex items-center">
                    <label htmlFor="yearSelect" className="text-gray-700 mr-3 font-medium">Select Year:</label>
                    <select
                        id="yearSelect"
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(e.target.value)}
                        className="p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500"
                    >
                        <option value="this-year">This Year</option>
                        <option value="last-year">Last Year</option>
                    </select>
                </div>
            </div>

            {/* Display error message if exists */}
            {error && (
                <div className="mb-4 p-4 bg-red-100 text-red-800 border border-red-300 rounded-lg">
                <p>Error: {error}</p>
                </div>
            )}

            {loading ? (
                <Spinner text="Fetching analytics data..." />
            ) : (
                <div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8 fade-in">
                        {/* Sent Messages Card */}
                        <div className="bg-white shadow-md rounded-lg p-4 text-center">
                            <h4 className="text-lg font-semibold text-gray-600">Total Sent Messages</h4>
                            <p className="text-2xl font-bold text-green-500">{totalSentMessages}</p>
                        </div>

                        {/* Failed Messages Card */}
                        <div className="bg-white shadow-md rounded-lg p-4 text-center">
                            <h4 className="text-lg font-semibold text-gray-600">Total Failed Messages</h4>
                            <p className="text-2xl font-bold text-red-500">{totalFailedMessages}</p>
                        </div>

                        {/* Success Rate Card */}
                        <div className="bg-white shadow-md rounded-lg p-4 text-center">
                            <h4 className="text-lg font-semibold text-gray-600">Overall Success Rate</h4>
                            <p className="text-2xl font-bold text-blue-500">{successRate}%</p>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8 fade-in">
                        {/* Sent Messages Card */}
                        <div className="bg-white shadow-md rounded-lg p-4 text-center">
                            <h4 className="text-lg font-semibold text-gray-600">Total Sent Flows</h4>
                            <p className="text-2xl font-bold text-green-500">{totalSentFlows}</p>
                        </div>

                        {/* Failed Messages Card */}
                        <div className="bg-white shadow-md rounded-lg p-4 text-center">
                            <h4 className="text-lg font-semibold text-gray-600">Total Failed Flows</h4>
                            <p className="text-2xl font-bold text-red-500">{totalFailedFlows}</p>
                        </div>

                        {/* Success Rate Card */}
                        <div className="bg-white shadow-md rounded-lg p-4 text-center">
                            <h4 className="text-lg font-semibold text-gray-600">Overall Success Rate</h4>
                            <p className="text-2xl font-bold text-blue-500">{flowSuccessRate}%</p>
                        </div>
                    </div>

                    {/* Line chart */}
                    <div className="bg-white shadow-md rounded-lg p-6 mb-8 slide-up" style={{ height: '450px' }}>
                        <Line options={options} data={chartData} height={450} />
                    </div>

                    {/* Monthly Summary Table */}
                    <h3 className="text-xl font-semibold text-gray-700 mb-4">Monthly Summary</h3>
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
                            <thead>
                                <tr className="bg-white">
                                    <th className="text-left py-3 px-4 uppercase font-semibold text-xs text-gray-600">Month</th>
                                    <th className="text-left py-3 px-4 uppercase font-semibold text-xs text-gray-600">Sent Messages</th>
                                    <th className="text-left py-3 px-4 uppercase font-semibold text-xs text-gray-600">Failed Messages</th>
                                    <th className="text-left py-3 px-4 uppercase font-semibold text-xs text-gray-600">Success Rate</th>
                                    <th className="text-left py-3 px-4 uppercase font-semibold text-xs text-gray-600">Sent Flows</th>
                                    <th className="text-left py-3 px-4 uppercase font-semibold text-xs text-gray-600">Failed Flows</th>
                                    <th className="text-left py-3 px-4 uppercase font-semibold text-xs text-gray-600">Success Rate</th>
                                </tr>
                            </thead>
                            <tbody>
                                {months.map((month, index) => (
                                    <tr key={index} className="border-b hover:bg-gray-100">
                                        <td className="py-3 px-4 text-sm text-gray-700">{month}</td>
                                        <td className="py-3 px-4 text-sm text-gray-700">{reportData.sent_messages[index + 1] || 0}</td>
                                        <td className="py-3 px-4 text-sm text-gray-700">{reportData.failed_messages[index + 1] || 0}</td>
                                        <td className="py-3 px-4 text-sm text-gray-700">
                                            {calculateSuccessRate(reportData.sent_messages[index + 1] || 0, reportData.failed_messages[index + 1] || 0)}%
                                        </td>
                                        <td className="py-3 px-4 text-sm text-gray-700">{reportData.sent_flows[index + 1] || 0}</td>
                                        <td className="py-3 px-4 text-sm text-gray-700">{reportData.failed_flows[index + 1] || 0}</td>
                                        <td className="py-3 px-4 text-sm text-gray-700">
                                            {calculateSuccessRate(reportData.sent_flows[index + 1] || 0, reportData.failed_flows[index + 1] || 0)}%
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Analytics;
