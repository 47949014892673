import React, { useState, useEffect, useRef } from 'react';
import CustomBreadcrumbs from './CustomBreadcrumbs';

const TemplateApproval = () => {
  const [templates, setTemplates] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [openDropdown, setOpenDropdown] = useState(null);  // Track which dropdown is open
  const dropdownRef = useRef(null);  // Reference for dropdown

  const perPage = 4;

  useEffect(() => {
    fetchTemplates();
  }, [currentPage, searchTerm]);

  useEffect(() => {
    // Close dropdown if clicked outside
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdown(null);  // Close dropdown if click is outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const fetchTemplates = async () => {
    // Fetch templates from API
    const response = await fetch(`/api/message_templates?page=${currentPage}&search=${searchTerm}`);
    const data = await response.json();
    // console.log("data:", data);
    setTemplates(data.templates);
    setTotalPages(data.templates.length > 0 ? data.total_pages : 1);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const toggleDropdown = (id) => {
    setOpenDropdown(openDropdown === id ? null : id);  // Toggle dropdown open/close
  };

  return (
    <div className="container mx-auto p-6 font-rubik">
      <CustomBreadcrumbs />
      {/* Header with Title and Search */}
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">Approval</h2>
      </div>
    </div>
  );
};

export default TemplateApproval;
